<template>
  <div>
    <NavBar :title="'账单明细'"></NavBar>
    <div class="content">
      <div class="filds">
        <div class="type">类型</div>
        <div class="desc">描述</div>
        <div class="money">金额</div>
      </div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          class="items"
          @load="queryList"
        >
          <div class="item" v-for="item in list" :key="item.id">
            <div class="type">{{ fomattType(item.type) }}</div>
            <div class="desc">
              <p>{{ item.remark || '暂无' }}</p>
              <p>{{ item.created_at_format }}</p>
            </div>
            <div class="money">{{ fomattMoney(item) }}</div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import { List, PullRefresh } from 'vant'
export default {
  name: 'moneyDetail',
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      list: [],
      finished: false,
      loading: false,
      refreshing: false,
      queryParams: {
        page: 1,
        limit: 10,
      },
    }
  },
  methods: {
    fomattMoney(item) {
      if (item.flow === '1') {
        return '-' + item.num
      } else {
        return '+' + item.num
      }
    },
    fomattType(type) {
      if (type === '1') {
        return '商家提现'
      }
      if (type === '3') {
        return '商家服务费'
      }
      if (type === '5') {
        return '商家充值'
      }
    },
    onRefresh() {
      this.queryParams.page = 1
      this.refreshing = true
      this.queryList()
    },
    queryList() {
      this.loading = true
      this.finished = false
      this.$http({
        url: '/agent/balanceLogIndex',
        method: 'get',
        data: this.$http.adornParams(this.queryParams),
      })
        .then(({ data }) => {
          this.list = data.data
          this.loading = false
          if (data.last_page > this.queryParams.page) {
            this.page++
          } else {
            this.refreshing = false
            this.finished = true
          }
        })
        .catch(() => {
          this.finished = true
          this.loading = false
          this.refreshing = false
        })
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  height: 100vh;
}
.filds {
  display: flex;
  background: #f9f9f9;
  text-align: center;
  height: 48px;
  > div {
    margin: auto 0;
  }
  > div:nth-child(1) {
    border-right: 1px solid #d9d9d9;
  }
  > div:nth-child(2) {
    border-right: 1px solid #d9d9d9;
  }
  > div:nth-child(3) {
    width: 30%;
  }
}
.type {
  width: 25%;
}
.desc {
  width: 60%;
  padding-left: 10px;
  text-align: left;
  p {
    margin: 0;
  }
  p:nth-child(2) {
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
  }
}
.money {
  width: 30%;
  font-size: 16px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
}
.item {
  height: 44px;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  display: flex;
  padding-top: 14px;
}
</style>
